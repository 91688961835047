.footer {
	margin-top: auto;
}

.footer-container {
	background: #1e1e1e;
	font-size: 16px;
	color: @white;
	padding-top: 45px;
	padding-bottom: 73px;

	@media only screen and (max-width: (@screen-tablet-max)) {
		padding-top: 45px;
		padding-bottom: 45px;
	}

	.content {
	}

	.divider {
		margin-bottom: 22.5px;
	}

	.logo {
		width: 211px;
		height: auto;
		margin-bottom: 25px;

		@media only screen and (max-width: (@screen-tablet-max)) {
			width: 131px;
		}
	}

	.copyright {
		max-width: 496px;
		color: #ccc;
		font-size: 16px;
		font-weight: 400;
		line-height: 110%; /* 17.6px */
		margin-bottom: 28px;

		@media only screen and (max-width: (@screen-tablet-max)) {
			font-size: 18px;
		}
	}

	.socials {
		display: flex;
		column-gap: 11px;
		margin-top: 16px;
	}

	.menu-container {
		.title {
			color: @white;
			font-size: 20px;
			font-weight: 700;
			line-height: 140%; /* 28px */
			text-transform: capitalize;
			margin-bottom: 20px;

			@media only screen and (max-width: (@screen-tablet-max)) {
				line-height: 160%; /* 32px */
				margin-bottom: 28px;
			}
		}

		.menu-list {
			display: flex;
			flex-direction: column;
			row-gap: 7px;

			@media only screen and (max-width: (@screen-tablet-max)) {
				row-gap: 22.5px;
			}

			a {
				color: #ccc;
				font-size: 20px;
				font-weight: 400;
				line-height: 140%; /* 28px */

				@media only screen and (max-width: (@screen-tablet-max)) {
					font-size: 16px;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';